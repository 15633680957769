.calendar {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--calendar-grid-gap);
}

.header {
  display: none;
}

@media (min-width: 1200px) {
  .calendar {
    grid-template-columns: repeat(7, 1fr);
  }
  .header {
    display: inline;
    height: 1.25rem;
    padding: 0 var(--calender-grip-padding);
  }
}
