.stageNav {
  height: 6rem;
}

.navHeader {
  text-align: center;
}

.special {
  color: var(--text-color-firstP);
}

.specialStage {
  display: flex;
  justify-content: center;
}

.stages {
  display: flex;
  justify-content: space-evenly;
  margin: var(--spacing) 0;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 2px solid var(--bg-color-secondary);
}

.faded {
  opacity: 0.5;
}

.linkContent {
  padding: var(--spacing) var(--spacing-s);
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  background-image: linear-gradient(var(--text-color-main), var(--text-color-main));
  background-size: 0% 1px;
  background-position-y: 81%;
  background-position-x: 50%;
  background-repeat: no-repeat;
}
.normal {
  opacity: 0.5;
}
.active,
.underlineYellow {
  padding-bottom: 11px;
  border-bottom: 1px solid var(--text-color-main);
}

.underlineYellow {
  border-bottom: 1px solid var(--text-color-firstP);
}

.specialEvents {
  justify-content: start;
  gap: var(--spacing);
}

@media (max-width: 1500px) {
  .linkContent {
    font-size: var(--font-size-s);
  }
}
@media (max-width: 1500px) and (min-width: 1200px) {
  .linkContent {
    font-size: var(--font-size-s);
    background-image: none;
  }
}
@media (max-width: 1200px) {
  .underline {
    padding-bottom: 10px;
  }
  .stageNav {
    padding: 0;
  }
}
