@font-face {
  font-family: 'Thin';
  src: url('../font/HKNova-Thin.eot');
  src: url('../font/HKNova-Thin.woff') format('woff');
  src: url('../font/HKNova-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'ExtraLight';
  src: url('../font/HKNova-ExtraLight.eot');
  src: url('../font/HKNova-ExtraLight.woff') format('woff');
  src: url('../font/HKNova-ExtraLight.woff2') format('woff2');
}
@font-face {
  font-family: 'Light';
  src: url('../font/HKNova-Light.eot');
  src: url('../font/HKNova-Light.woff') format('woff');
  src: url('../font/HKNova-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Regular';
  src: url('../font/HKNova-Regular.eot');
  src: url('../font/HKNova-Regular.woff') format('woff');
  src: url('../font/HKNova-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Medium';
  src: url('../font/HKNova-Medium.eot');
  src: url('../font/HKNova-Medium.woff') format('woff');
  src: url('../font/HKNova-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'SemiBold';
  src: url('../font/HKNova-SemiBold.eot');
  src: url('../font/HKNova-SemiBold.woff') format('woff');
  src: url('../font/HKNova-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Bold';
  src: url('../font/HKNova-Bold.eot');
  src: url('../font/HKNova-Bold.woff') format('woff');
  src: url('../font/HKNova-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'ExtraBold';
  src: url('../font/HKNova-ExtraBold.eot');
  src: url('../font/HKNova-ExtraBold.woff') format('woff');
  src: url('../font/HKNova-ExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Heavy';
  src: url('../font/HKNova-Heavy.eot');
  src: url('../font/HKNova-Heavy.woff') format('woff');
  src: url('../font/HKNova-Heavy.woff2') format('woff2');
}
