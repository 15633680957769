@import './reset.css';
@import './fonts.css';

:root {
  /* COLORS */
  --bg-color-main: rgb(0, 37, 72);
  --bg-color-secondary: rgba(228, 233, 240, 0.1);
  --bg-color-tertiary: #009ac7;
  --bg-color-fourth: #e4e9f0;
  --text-color-main: #ffffff;
  --text-color-firstP: #c3a86b;
  --text-color-secondP: #c0c0c0;
  --text-color-thirdP: #cd7f32;

  /* FONTS */
  --font-style-thin: 'Thin', sans-serif;
  --font-style-extraLight: 'ExtraLight', sans-serif;
  --font-style-light: 'Light', sans-serif;
  --font-style-regular: 'Regular', sans-serif;
  --font-style-medium: 'Medium', sans-serif;
  --font-style-semibold: 'SemiBold', sans-serif;
  --font-style-bold: 'Bold', sans-serif;
  --font-style-extrabold: 'ExtraBold', sans-serif;
  --font-style-heavy: 'Heavy', sans-serif;
  --font-size-xs: 0.5rem;
  --font-size-s: 0.75rem;
  --font-size-m: 1rem;
  --font-size-l: 1.5rem;
  --font-size-xl: 2rem;
  --font-size-xxl: 4rem;
  --font-size-3xl: 8rem;

  /* BORDER-RADIUS */
  --border-radius: 1.3rem;

  /* BOX-SHADOW */
  --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  /* SPACING */
  --spacing-s: 0.75rem;
  --spacing: 1.5rem;
  --spacing-ml: 2rem;
  --spacing-l: 3rem;
  --spacing-xl: 6rem;
  --spacing-xxl: 9rem;
  --calender-grip-padding: 1rem;
  --calendar-grid-gap: 2rem;
}

* {
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  box-sizing: inherit;
}

body {
  line-height: 1;
  background-color: var(--bg-color-main);
  color: var(--text-color-main);
  font-family: var(--font-style-regular);
}

h1,
.h1 {
  font-size: var(--font-size-3xl);
  color: var(--text-color-main);
}

h2,
.h2 {
  font-size: var(--font-size-l);
  line-height: 1.3;
}

h3,
.h3 {
  font-size: var(--font-size-m);
  line-height: 1.2;
}

h4,
.h4 {
  font-size: var(--font-size-s);
  line-height: 1.3;
}

h5,
.h5 {
  font-family: var(--font-style-extraLight);
  font-size: var(--font-size-xs);
}

p {
  font-size: var(--font-size-m);
  line-height: 1.3;
  margin: 0;
}
a {
  text-decoration: none;
  color: var(--text-color-main);
}

.container {
  height: 100%;
  width: 90%;
  margin: var(--spacing-xxl) auto 0 auto;
}

.icon,
.icon:hover {
  cursor: pointer;
}

.splitContainer > * {
  flex: 1 1 auto;
}

.specialEventBtn {
  color: var(--bg-color-main);
  background-color: var(--text-color-firstP);
  width: fit-content;
}
.specialEventBtn:hover {
  color: var(--text-color-firstP);
  border-color: var(--text-color-firstP);
}

@media (max-width: 600px) {
  h1,
  .h1 {
    font-size: var(--font-size-xxl);
  }
}
