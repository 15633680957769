.titleAndCount,
.showCounter {
  display: flex;
  align-items: baseline;
  gap: var(--spacing-ml);
}

.showCounter {
  flex-wrap: wrap;
}

.special {
  color: var(--text-color-firstP);
}

.row {
  flex-direction: row;
  margin-bottom: var(--spacing);
  align-items: baseline;
}

.column {
  flex-direction: column;
}

.dateTimeDisplay {
  display: flex;
  align-items: baseline;
  gap: var(--spacing);
}

.displayValue {
  min-width: var(--spacing-xl);
}

.xl {
  font-size: 3rem;
}

.lg {
  font-size: 2rem;
}

.base {
  font-size: 1.5rem;
}
