@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  position: fixed; /* or absolute */
  top: calc(50% - var(--spacing));
  left: calc(50% - var(--spacing));
  width: var(--spacing-l);
  height: var(--spacing-l);
  border: 5px solid var(--bg-color-secondary);
  border-top: 5px solid var(--bg-color-tertiary);
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
