.checkboxContainer {
  display: flex;
  align-items: center;
}

.mainCheck {
  gap: var(--spacing);
}

.checkbox,
.indeterminateCheckbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--text-color-main);
  margin: 0;
  font: inherit;
  color: var(--text-color-main);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  display: grid;
  place-content: center;
  background-color: var(--bg-color-main);
  border: 1px solid var(--bg-color-tertiary);
}
.checkbox::before {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 10px 10px var(--text-color-main);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.indeterminateCheckbox::before {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  box-shadow: inset 10px 10px var(--text-color-main);
  clip-path: polygon(13% 38%, 89% 38%, 89% 66%, 13% 66%);
}

.checkbox:checked::before {
  transform: scale(1);
}

.checkbox:hover {
  cursor: pointer;
}

.checkbox:checked {
  background: var(--bg-color-tertiary);
}

.disabled {
  visibility: hidden;
}

@media (max-width: 100px) {
  .checkboxContainer {
    gap: 0;
  }
  .mainCheck {
    gap: var(--spacing-s);
  }
}
