.uploadResumeContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.uploadResumeContainer > button {
  align-self: center;
}

.uploadList {
  width: 100%;
}

.uploadListItem {
  display: flex;
  outline: 2px solid var(--bg-color-secondary);
  border-radius: var(--border-radius);
  margin-top: var(--spacing-s);
  align-items: center;
  justify-content: center;
}

.card {
  margin-top: 0;
}

.noStage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-s);
}

.uploadResumeCard {
  opacity: 1;
}
@media (max-width: 850px) {
  .uploadListItem {
    flex-direction: column;
  }
  .stageData {
    display: flex;
    gap: var(--spacing-s);
    align-items: center;
  }
}
