.rankingWidget {
  flex-direction: column;
  gap: var(--spacing);
}

.rankingHeader {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  margin-bottom: var(--spacing);
}
.rankingTitle {
  margin-bottom: var(--spacing);
}

.listItemTitle {
  height: var(--spacing);
  margin-bottom: var(--spacing-s);
}

.first {
  color: var(--text-color-firstP);
}

.second {
  color: var(--text-color-secondP);
}

.third {
  color: var(--text-color-thirdP);
}

.rankingList {
  display: flex;
  flex-wrap: wrap;
}

.rankingList > * {
  min-width: 15rem;
  padding: var(--spacing-s);
  padding-left: 0;
  flex: 1 1 auto;
}

.listItem {
  display: flex;
  gap: var(--spacing);
  margin-top: var(--spacing-s);
  align-items: center;
}

.listItemNumber {
  font-size: var(--font-size-l);
}

.notAchieved {
  opacity: 0.5;
}

.listContainer {
  max-height: 28rem;
  overflow-x: scroll;
  /* hide scroll IE and Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/*hide scroll Chrome, Safari, Opera*/
.listContainer::-webkit-scrollbar {
  display: none;
}
