.button {
  border: none;
  display: flex;
  padding: 0.5rem;
  font-weight: var(--font-size-m);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--bg-color-main);
  background: var(--text-color-main);
  width: var(--spacing-xxl);
  transition: 0.3s;
  border: 1px solid transparent;
  min-height: var(--spacing);
  max-height: var(--spacing);
}

.button:hover {
  cursor: pointer;
  background: none;
  color: var(--text-color-main);
  border: 1px solid var(--text-color-main);
}

.button:disabled,
.button:disabled:hover {
  opacity: 0.5;
  cursor: auto;
}

.outlined {
  background: none;
  color: var(--text-color-main);
  border: 1px solid var(--text-color-main);
}

.outlined:hover {
  background: var(--text-color-main);
  color: var(--bg-color-main);
}

.action {
  background: var(--bg-color-tertiary);
  color: var(--text-color-main);
  border: 1px solid transparent;
}
.action:hover {
  background: none;
  border: none;
  border: 1px solid var(--bg-color-tertiary);
}

.upload,
.upload:disabled {
  padding: var(--spacing);
  border-radius: var(--border-radius);
  background: var(--bg-color-main);
  color: var(--bg-color-tertiary);
  border: 1px solid var(--bg-color-tertiary);
}

.upload:disabled {
  opacity: 0.5;
  border: none;
}

.upload:hover {
  background: var(--bg-color-tertiary);
  border: 1px solid var(--bg-color-tertiary);
  /* border: 1px solid transparent; */
}
.upload:hover:disabled {
  background: none;
  /* border: 1px solid var(--bg-color-tertiary); */
}
.tab {
  border-radius: none;
  background: var(--bg-color-main);
  border: none;
  outline: none;
  color: var(--text-color-main);
  width: 100%;
}

.tab:hover {
  border: none;
  outline: none;
  background-color: var(--bg-color-secondary);
}

.small {
  width: var(--spacing-xl);
}

.large {
  font-size: var(--font-size-l);
  width: calc(var(--spacing-l) * 4);
}
.disabled {
  opacity: 0.5;
  cursor: auto;
}
.mobile {
  flex-direction: row;
  padding: 0.5rem;
  width: var(--spacing);
}
