.loginWrapper {
  color: var(--text-color-firstP);
  position: relative;
}

.logo {
  display: flex;
  border: 1px solid var(--text-color-firstP);
  padding: 0.5rem;
  height: var(--spacing);
}

.statsWrapper {
  overflow: visible;
  width: 15rem;
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  position: absolute;
  top: 2.9rem;
  right: 0;
}

.loginWrapper:hover {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  cursor: pointer;
}

.logo {
  color: var(--text-color-firstP);
}

.logoActive {
  transition: 0.3s ease-in-out;
  fill: var(--bg-color-primary);
  background-color: var(--text-color-firstP);
}
