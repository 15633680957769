.wrapper {
  display: flex;
  gap: var(--spacing);
}

.left {
  flex: 0.7;
}

.right {
  flex: 0.3;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  margin-top: var(--spacing-l);
  justify-content: flex-end;
}

.hidden {
  display: none;
}

.mobile > button {
  order: 3;
}

.chevronIcon {
  padding: var(--spacing-s);
}

.list {
  transition: max-height 1s;
  margin-bottom: var(--spacing-s);
}

.open {
  max-height: 100%;
  transition: max-height ease-in-out 0.5s;
}

@media (max-width: 1000px) {
  .wrapper {
    display: block;
  }
  .right {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    transition: 0.3s;
    background-color: var(--bg-color-main);
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  .hidden {
    display: block;
  }
}
