.stageTabs {
  width: 100%;
  justify-content: space-around;
  border-bottom: 2px solid var(--bg-color-secondary);
  display: none;
  margin-bottom: var(--spacing);
}

.wrapper {
  display: flex;
  gap: var(--spacing-l);
}

.wrapperNoActivities {
  display: block;
  margin-top: var(--spacing);
}

.left {
  min-width: 60%;
}

.right {
  width: 40%;
}

.counters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing-l) var(--spacing-xl) 0 var(--spacing-xl);
}

.tdfCounter {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing);
}

.counters > *:last-child {
  align-self: end;
}

.active {
  background-color: var(--bg-color-secondary);
}

@media (max-width: 1000px) {
  .stageTabs {
    display: flex;
  }
}
@media (max-width: 1300px) {
  .right {
    flex: 1 1 30%;
  }
  .left {
    flex: 1 1 70%;
  }
}

@media (max-width: 650px) {
  .counters {
    padding: var(--spacing-s);
    padding-top: var(--spacing-l);
    align-items: center;
  }
}
