.event {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-l) 0;
  border-top: 2px solid;
}

.eventHome {
  flex-direction: row;
  padding: 0 0 0 var(--spacing);
  border-top: none;
  border-left: 2px solid;
  gap: var(--spacing);
  margin-bottom: var(--spacing);
}

.eventInactive {
  border-color: rgba(255, 255, 255, 0.5);
}

.eventCurrent {
  background-color: rgba(217, 217, 217, 0.06);
  border-color: var(--bg-color-tertiary);
}

.body {
  flex: 1;
}

.bodyHome {
  display: flex;
  gap: var(--spacing-s);
}

.bodyHome > * {
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.days {
  display: flex;
}

.day {
  flex: 1;
  padding: 0 var(--calender-grip-padding);
  font-family: var(--font-style-light);
  font-size: 2.5rem;
}

.dayCurrent {
  color: var(--bg-color-tertiary);
}

.dayInactive {
  color: rgba(255, 255, 255, 0.5);
}

.title {
  max-width: 32rem;
  height: 6rem;
  margin-top: var(--spacing);
  padding: 0 var(--calender-grip-padding);
  font-size: var(--font-size-m);
  line-height: var(--spacing);
}

.titleHome {
  margin-bottom: var(--spacing);
}
.bulletList {
  max-width: 32rem;
  padding: 0 var(--calender-grip-padding);
  line-height: 1.3;
}

.bulletListItem {
  display: inline-block;
  font-family: var(--font-style-extraLight);
  font-size: var(--font-size-s);
  margin-top: var(--spacing-s);
}

.bulletListItemHighlighted {
  background-color: var(--bg-color-tertiary);
}

.footer {
  display: flex;
  margin-top: 5rem;
}
.footerHome {
  margin: 0;
  flex-direction: column;
  gap: var(--spacing);
}

.footerItem {
  flex: 1;
  padding: 0 var(--calender-grip-padding);
  font-family: var(--font-style-extraLight);
  font-size: 0.75rem;
  line-height: 1.5;
}

.footerItem:not(:last-child) {
  margin-right: var(--calendar-grid-gap);
}
