.specialEventAnnounce,
.specialContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing);
}

.specialEventAnnounce {
  color: var(--text-color-firstP);
  width: 100%;
}
