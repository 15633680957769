.stats > li {
  display: flex;
  gap: var(--spacing-s);
  height: var(--spacing);
  align-items: baseline;
}

.stats {
  margin-top: var(--spacing-s);

  display: flex;
  gap: var(--spacing);
  flex-direction: row;
}

@media (max-width: 450px) {
  .stats {
    flex-direction: column;
    align-items: flex-end;
  }
}
