.listWrapper {
  margin: var(--spacing) 0 var(--spacing-xxl) 0;
}

.activitiesList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  margin-top: var(--spacing);
}

.activityListItem {
  display: flex;
}

.activitiesList > * {
  display: flex;
  gap: var(--spacing);
  align-items: center;
}

@media (max-width: 1000px) {
  .activitiesList > * {
    gap: var(--spacing-s);
  }
}
