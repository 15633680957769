.modalContainer,
.overlay {
  display: flex;
  gap: var(--spacing);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-color-secondary);
  z-index: 100;
}

.modalContainer {
  min-height: var(--spacing-xxl);
  width: 85%;

  /* hide scroll IE and Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: var(--bg-color-main);
  position: relative;
  border-radius: var(--border-radius);
  padding: var(--spacing-s);
  box-shadow: var(--box-shadow);
}

.close {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 0;
}

.close:hover {
  cursor: pointer;
}
