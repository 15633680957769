.headerWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  height: var(--spacing-xl);
  align-items: center;
  top: 0;
  background-image: linear-gradient(to bottom, var(--bg-color-main), rgba(0, 0, 255, 0));
  z-index: 100;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0 var(--spacing-l);
}

.title,
.titleFirst {
  display: flex;
  gap: var(--spacing-s);
}

.titleFirst img {
  align-self: center;
}

.title {
  align-items: center;
  gap: var(--spacing-l);
}

.headerBtnGroup {
  display: flex;
  gap: var(--spacing-s);
}

@media (max-width: 600px) {
  .title {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-s);
  }

  .headerWrapper {
    padding-top: var(--spacing);
  }

  .titleFirst {
    flex-direction: column;
  }
  .headerBtnGroup {
    flex-wrap: wrap;
  }
}

@media (max-width: 1000px) {
  .header {
    margin: auto var(--spacing);
  }
}
