.errorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing);
  margin-bottom: var(--spacing-l);
}

.fullScreen {
  height: 100vh;
}
