.cardWrapper {
  transition: 0.3s;
  box-shadow: var(--box-shadow);
  offset: 2px solid var(--bg-color-secondary);
  display: flex;
  gap: 2%;
  border-radius: var(--border-radius);
  flex-grow: 1;
}

.cardActivity {
  background-color: var(--bg-color-secondary);
  padding: var(--spacing-s);
  border-radius: var(--border-radius);
  flex-grow: 1;
}

.cardWrapper:hover {
  opacity: 1;
  transform: scale(1.02);
}

.cardHeading {
  display: flex;
  justify-content: space-between;
}

.cardStage {
  padding: var(--spacing-s);
  padding-left: 0;
  align-self: center;
}

.dateMaps {
  display: flex;
  gap: var(--spacing);
}

.mapIcon,
.mapIcon:hover {
  margin-right: var(--spacing);
  cursor: pointer;
}

.cardTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.cardTitle > h3 {
  margin-right: var(--spacing);
}

.date {
  font-size: var(--font-size-sm);
  font-family: var(--font-style-thin);
}

.cardContent {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: var(--spacing-s);
  visibility: visible;
  transition: visibility 0s, opacity 0.5s linear;
  padding-top: var(--spacing-s);
}

.cardContent > *,
.speed > * {
  display: flex;
  gap: var(--spacing);
}

.disabled {
  opacity: 0.5;
}

.hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
  padding: 0;
}

.cardHeadingClosed {
  align-items: center;
}

.mobile {
  font-size: var(--font-size-sm);
  margin-right: 0;
}

@media (max-width: 751px) {
  .cardWrapper {
    min-width: auto;
  }
}

@media (max-width: 1000px) {
  .cardWrapper {
    flex-direction: column;
  }
  .cardContent {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .cardContent > * {
    justify-content: space-between;
  }
}
