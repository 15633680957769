.listWrapper {
  display: flex;
  flex-direction: column;
}

.listHeading {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.list {
  display: flex;
  flex-direction: column;
}

.listItem {
  margin-top: var(--spacing-s);
}
