.wrapper {
  overflow-x: scroll;
  /* hide scroll IE and Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 28rem;
  flex-shrink: 0;
}
/*hide scroll Chrome, Safari, Opera*/
.wrapper::-webkit-scrollbar {
  display: none;
}

.caroussel {
  width: 25rem;
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  height: 100vh;
  background-color: var(--bg-color-secondary);
}

.image {
  width: 25rem;
  background-color: var(--bg-color-secondary);
}

@media (max-width: 1000px) {
  .wrapper {
    display: none;
    width: 0;
  }

  .caroussel {
    height: 0;
    width: 0;
  }
}
