.widgets,
.dataWidgets,
.dataWidget,
.headingElements {
  display: flex;
}

.headingTitle {
  z-index: 2;
}

.headingElements {
  justify-content: space-between;
  align-items: center;
  margin: var(--spacing-l) var(--spacing-l) 0 var(--spacing-l);
}

.widgets {
  margin-right: var(--spacing-l);
}

.dataWidgets {
  margin-bottom: var(--spacing-l);
}

.dataWidget {
  flex-direction: column;
  padding: 0 var(--spacing);
}

.mobileButtons {
  flex-wrap: wrap;
  gap: var(--spacing-s);
  justify-content: space-evenly;
  margin-bottom: var(--spacing);
}

@media (max-width: 600px) {
  .widgets {
    margin-right: 0;
  }
}

@media (min-width: 1000px) {
  .headingTitle {
    position: absolute;
    top: 250px;
    left: 180px;
  }
  .dataWidgets {
    gap: var(--spacing-l);
  }
}

@media (max-width: 1000px) {
  .headingTitle {
    position: static;
    margin-bottom: var(--spacing-l);
    text-align: left;
  }
  .dataWidgets {
    flex-direction: column;
    gap: var(--spacing-l);
  }
  .widgets {
    display: block;
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .headingTitle {
    top: 150px;
    left: 100px;
  }
  .dataWidgets {
    flex-direction: column;
    margin-top: 25rem;
  }
}
@media (min-width: 1300px) and (max-width: 1600px) {
  .widgets {
    gap: var(--spacing);
  }
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .headingTitle {
    top: 150px;
    left: 100px;
  }
  .dataWidgets {
    flex-direction: column;
    margin-top: 25rem;
  }
}

@media (min-width: 1300px) {
  .headingTitle {
    position: absolute;
    top: 15rem;
    left: 16rem;
  }

  .dataWidgets {
    margin: 27rem 0 var(--spacing-xl) 0;
    width: 100%;
  }

  .dataWidget {
    justify-content: space-between;
    gap: 0;
  }
}

@media (min-width: 1600px) {
  .widgets {
    gap: var(--spacing-xxl);
  }
  .dataWidgets {
    gap: var(--spacing-xxl);
    justify-content: start;
  }
}
