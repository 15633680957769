.xs {
  font-size: var(--font-size-xs);
}
.sm {
  font-size: var(--font-size-s);
}
.base {
  font-size: var(--font-size-m);
}
.lg {
  font-size: var(--font-size-l);
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.justify {
  text-align: justify;
}
.upperCase {
  text-transform: uppercase;
}
.action {
  color: var(--bg-color-tertiary);
}
.thin {
  font-family: var(--font-style-thin);
}
.light {
  font-family: var(--font-style-extraLight);
}

.winner {
  color: var(--text-color-firstP);
}
